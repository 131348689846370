<!-- <div mat-dialog-close class="popin-closeIcon" *ngIf="!isMobile && isPopin">
  <mat-icon svgIcon="close"></mat-icon>
</div> -->
<!-- 
<div mat-dialog-close class="popin-close" *ngIf="isMobile && isPopin" style="top: 0">
  <mat-icon>close</mat-icon>
</div> -->


<h3 fxLayout="column" [fxLayoutAlign]="'center ' + titleAlign" mat-dialog-title *ngIf="isPopin">
  <div fxLayout fxLayoutAlign="center center">
    <span [innerHTML]="title"></span>
  </div>
</h3>

<div mat-dialog-content *ngIf="isLoading" style="height: 300px">
  <loading [absolute]="true" [defaultAnimation]="false"></loading>
</div>


<ng-container *ngIf="!isLoading">
  <mat-form-field class="popin-recruitersList-autocomplete" appearance="standard" floatLabel="always"
    style="font-size: inherit; margin: 0;text-align: left">

    <input aria-label="Trouver des membres" matInput #input type="text" [formControl]="usersListForm"
      [matAutocomplete]="list" placeholder="Trouver des membres" style="line-height: 1">

    <div matPrefix fxLayout style="margin-right: 5px">
      <mat-icon>person</mat-icon>
      <mat-icon style="margin-left: -9px">search</mat-icon>
    </div>


    <mat-autocomplete #list="matAutocomplete" [displayWith]="displayFn"
      (optionSelected)="autoCompleteOptionSelected($event.option)">
      <ng-container *ngFor="let user of filteredUsers | async">
        <mat-option [value]="user.prenom + user.nom" [id]="user.id" *ngIf="user.prenom && user.nom">

          <ng-container *ngIf="user?.prenom && user?.nom">
            {{user.prenom}} {{user.nom}}
          </ng-container>



        </mat-option>

        <mat-option [value]="user?.recruteur?.prenom + user?.recruteur?.nom" [id]="user?.recruteur?.id"
          *ngIf="user?.recruteur?.prenom && user?.recruteur?.nom">

          <ng-container *ngIf="user?.recruteur?.prenom && user?.recruteur?.nom">
            {{user.recruteur.prenom}} {{user.recruteur.nom}}
          </ng-container>

        </mat-option>

        <mat-option [value]="user?.recruteur?.prenom" [id]="user?.recruteur?.id"
          *ngIf="user?.recruteur?.prenom && !user?.recruteur?.nom">

          {{user.recruteur.prenom}}

        </mat-option>

        <mat-option [value]="user?.prenom" [id]="user?.id" *ngIf="user?.prenom && user?.nom == null">

          {{user.prenom}}

        </mat-option>

      </ng-container>


    </mat-autocomplete>

    <button mat-button matSuffix mat-icon-button aria-label="effacer" (click)="clear($event)">
      <mat-icon>close</mat-icon>
    </button>

  </mat-form-field>

  <div *ngIf="canInviteMembers && isSelectionList">
    <button mat-button class="btn btn--capsule" (click)="inviteMember()">
      <mat-icon>group_add</mat-icon>
      Inviter des membres
    </button>
  </div>

  <br>

  <div class="popin-recruitersList-header" fxLayout fxLayoutAlign="flex-start center">
    <div fxLayout fxLayoutAlign="flex-start center" fxFlex="40" style="padding-left: 5px;">Nom</div>
    <div fxLayout fxLayoutAlign="flex-start center" fxFlex="25" style="padding-left: 12px;" *ngIf="!isManagerList">
      Rôle
    </div>
    <div fxLayout fxLayoutAlign="flex-start center" fxFlex="25" style="padding-left: 12px;" *ngIf="isManagerList"></div>
    <div fxLayout fxLayoutAlign="flex-start center" fxFlex="25" style="padding-left: 12px;">Statut</div>
    <div fxLayout fxLayoutAlign="flex-start center" fxFlex="7" *ngIf="isSelectionList">Ajouter</div>
  </div>

  <div mat-dialog-content fxLayout fxLayoutAlign="center start" ngClass.gt-sm="isDesktop" [class.scrollbar]="isPopin"
    [class.grey]="isPopin" [class.popin-recruitersList-static]="!isPopin">


    <mat-list #usersSelection fxFlex="100" *ngIf="isSelectionList">
      <mat-list-item *ngFor="let user of allMembersList;" [attr.userId]="user.id ? user.id : user.recruteur.id"
        [id]="user.id ? user.id : user.recruteur.id">
        <div fxLayout fxLayoutAlign="flex-start center" style="width: 100%;">

          <!-- <div fxLayout fxLayoutAlign="flex-start center" fxFlex="40"
            (click)=" handleClick($event, 
            checkbox,
            {checked: checkbox.checked, recruteurId: user?.id, recruteurRoleId: !isManagerList ? user.rolesForm?.value : 2, departementId: isPopin ? data.departement?.id : departement?.id });"> -->
          <div fxLayout fxLayoutAlign="flex-start center" [fxFlex]="!isManagerList ? 37 : (isPopin ? 37 : 40)">
            <div fxLayout fxLayoutAlign="flex-start center"
              *ngIf="user?.statut?.toLowerCase() === 'invitation en attente' || user?.statut?.toLowerCase() === 'invitation expirée'; else avatar">


              <div class="popin-recruitersList-shortname" style="border: 0">
                <mat-icon>schedule_send</mat-icon>
              </div>

              <span>
                {{user.prenom}} {{user.nom ? user.nom : ''}}
              </span>

            </div>

            <ng-template #avatar>
              <ng-container *ngIf="user?.prenom && user?.nom">
                <img class="popin-recruitersList-avatar" [src]="'data:image/jpeg;base64,' + user?.avatarBase64" alt=""
                  *ngIf="user?.avatarBase64">

                <div class="popin-recruitersList-shortname" *ngIf="!user?.avatarBase64 && user?.prenom && user?.nom">
                  {{user?.prenom[0] | titlecase}}{{user?.nom[0] | titlecase}}
                </div>

                <span>
                  {{user.prenom}} {{user.nom}}
                </span>
              </ng-container>

              <ng-container *ngIf="user?.recruteur?.prenom && user?.recruteur?.nom">
                <img class="popin-recruitersList-avatar"
                  [src]="'data:image/jpeg;base64,' + user?.recruteur?.avatarBase64" alt=""
                  *ngIf="user?.recruteur?.avatarBase64">

                <div class="popin-recruitersList-shortname"
                  *ngIf="!user?.recruteur?.avatarBase64 && user?.recruteur?.prenom && user?.recruteur?.nom">
                  {{user?.recruteur?.prenom[0] | titlecase}}{{user?.recruteur?.nom[0] | titlecase}}
                </div>

                <span>
                  {{user?.recruteur?.prenom}} {{user?.recruteur?.nom}}
                </span>

              </ng-container>


              <ng-container *ngIf="user?.prenom && !user?.nom">
                <img class="popin-recruitersList-avatar" [src]="'data:image/jpeg;base64,' + user?.avatarBase64" alt=""
                  *ngIf="user?.avatarBase64">

                <div class="popin-recruitersList-shortname" *ngIf="!user?.avatarBase64 && user?.prenom && !user?.nom">
                  {{user?.prenom[0] | titlecase}}{{user?.prenom[1] | titlecase}}
                </div>

                <span>
                  {{user?.prenom}}
                </span>

              </ng-container>

              <ng-container *ngIf="user?.recruteur?.prenom && !user?.recruteur?.nom">
                <img class="popin-recruitersList-avatar" [src]="'data:image/jpeg;base64,' + user?.avatarBase64" alt=""
                  *ngIf="user?.avatarBase64">

                <div class="popin-recruitersList-shortname"
                  *ngIf="!user?.recruteur?.avatarBase64 && user?.recruteur?.prenom && !user?.recruteur?.nom">
                  {{user?.recruteur?.prenom[0] | titlecase}}{{user?.recruteur?.prenom[1] | titlecase}}
                </div>

                <span>
                  {{user?.recruteur?.prenom}}
                </span>

              </ng-container>
            </ng-template>

          </div>
          <div [fxFlex]="isPopin ? 29 : 25" style="overflow: hidden;">
            <mat-form-field class="select-default" style="padding-right: 15px" *ngIf="!isManagerList">

              <!-- // MULTIPLE select -->
              <!-- <mat-select #select [formControl]="user.rolesForm" multiple (selectionChange)="updateRoles($event)"> -->

              <!-- // SIMPLE select -->
              <!-- <mat-select #select [formControl]="user.rolesForm" (selectionChange)="updateRoles($event, user)"> -->
              <mat-select #select [formControl]="user.rolesForm">
                <ng-container *ngFor="let role of roles">
                  <mat-option class="select-border__option" [value]="role.id" [id]="role.id"
                    style="text-transform: none;">
                    {{ role.nom }}
                  </mat-option>
                </ng-container>
              </mat-select>

            </mat-form-field>
          </div>
          <!-- 
          <div [fxFlex]="!isManagerList ? 21 : 25"
            style="flex-wrap: wrap; display: flex; align-items: center; font-weight: normal;"> -->
          <div [fxFlex]="!isPopin ? (isMobile ? 25.5 : 23.5) : 25"
            style="flex-wrap: wrap; display: flex; align-items: center; font-weight: normal;">

            <ng-container *ngIf="user?.statut?.toLowerCase() === 'actif'">
              <span class="dot green"></span>
            </ng-container>

            <ng-container *ngIf="user?.statut?.toLowerCase() === 'invitation en attente'">
              <span class="dot orange"></span>
            </ng-container>

            <ng-container *ngIf="user?.statut?.toLowerCase() === 'désactivé'">
              <span class="dot grey"></span>
            </ng-container>


            <ng-container *ngIf="user?.statut?.toLowerCase() === 'invitation expirée'">
              <span class="dot red"></span>
            </ng-container>
            {{user?.statut}}
          </div>

          <div [fxLayoutAlign]="isPopin ? 'flex-start center' : 'flex-start'" fxFlex="7">

            <!-- <mat-checkbox #checkbox
              [disabled]="!isSelectionList || !isManagerList && user?.checked && departement?.typeDepartementId === 1 || !hasPermissions || !isManagerList && user?.departementRoleId === 5  && departement || !isManagerList && userId === user.id  && departement || isManagerList && departement.countPosteAPourvoir !== 0 && allMembersChecked.length === 1 && user.checked "
              color="primary" [checked]="user.checked" [matTooltip]="handleTooltip(user)"
              (change)="updateUser($event,{recruteurId: user?.id, recruteurRoleId: !isManagerList ? user.rolesForm?.value : 2, departementId: isPopin ? data.departement?.id : departement?.id })">
            </mat-checkbox> -->

            <!-- <mat-checkbox #checkbox
              [disabled]="!isSelectionList || !isManagerList && user?.checked && departement?.typeDepartementId === 1 || !hasPermissions || !isManagerList && user?.departementRoleId === 5  && departement &&  data.context !== 'poste-a-pourvoir' || !isManagerList && userId === user.id  && departement || isManagerList && departement.countPosteAPourvoir !== 0 && allMembersChecked.length === 1 && user.checked "
              color="primary" [checked]="user.checked" [matTooltip]="handleTooltip(user)"
              (change)="checkedUser($event, user)">
            </mat-checkbox> -->


            <!-- <mat-checkbox #checkbox
              [disabled]="!isSelectionList || !isManagerList && user?.checked && departement?.typeDepartementId === 1 || 
              !hasPermissions || 
              !isManagerList && user?.departementRoleId === 5  && departement &&  data.context !== 'poste-a-pourvoir'  || 
              isManagerList && departement.countPosteAPourvoir !== 0 && allMembersChecked.length === 1 && user.checked ||
              user?.statut?.toLowerCase() === 'invitation en attente' || user?.statut?.toLowerCase() === 'invitation expirée' "
              color="primary" [checked]="user.checked" [matTooltip]="handleTooltip(user)"
              (change)="checkedUser($event, user)"> -->

            <mat-checkbox #checkbox [disabled]="!isSelectionList || !isManagerList && user?.checked && departement?.typeDepartementId === 1 || 
              !hasPermissions || 
              !isManagerList && user?.departementRoleId === 5  && departement &&  data.context !== 'poste-a-pourvoir'  || 
              isManagerList && departement.countPosteAPourvoir !== 0 && allMembersChecked.length === 1 && user.checked"
              color="primary" [checked]="user.checked" [matTooltip]="handleTooltip(user)"
              (change)="checkedUser($event, user)">
            </mat-checkbox>

          </div>

        </div>

      </mat-list-item>
    </mat-list>

    <mat-list #usersSelection fxFlex="100" *ngIf="!isSelectionList">
      <mat-list-item *ngFor="let user of usersList" [attr.userId]="user.id ? user.id : user.recruteur.id"
        [id]="user.id ? user.id : user.recruteur.id">

        <div fxLayout fxLayoutAlign="flex-start center" style="width: 100%;">
          <div fxLayout fxLayoutAlign="flex-start center" fxFlex="40">

            <ng-container *ngIf="user?.prenom && user?.nom">
              <img class="popin-recruitersList-avatar" [src]="'data:image/jpeg;base64,' + user?.avatarBase64" alt=""
                *ngIf="user?.avatarBase64">

              <div class="popin-recruitersList-shortname" *ngIf="!user?.avatarBase64 && user?.prenom && user?.nom">
                {{user?.prenom[0] | titlecase}}{{user?.nom[0] | titlecase}}
              </div>

              <span>
                {{user.prenom}} {{user.nom}}
              </span>
            </ng-container>

            <ng-container *ngIf="user?.recruteur?.prenom && user?.recruteur?.nom">
              <img class="popin-recruitersList-avatar" [src]="'data:image/jpeg;base64,' + user?.recruteur?.avatarBase64"
                alt="" *ngIf="user?.recruteur?.avatarBase64">

              <div class="popin-recruitersList-shortname"
                *ngIf="!user?.recruteur?.avatarBase64 && user?.recruteur?.prenom && user?.recruteur?.nom">
                {{user?.recruteur?.prenom[0] | titlecase}}{{user?.recruteur?.nom[0] | titlecase}}
              </div>

              <span>
                {{user?.recruteur?.prenom}} {{user?.recruteur?.nom}}
              </span>
            </ng-container>

            <ng-container *ngIf="user?.prenom && !user?.nom">
              <img class="popin-recruitersList-avatar" [src]="'data:image/jpeg;base64,' + user?.avatarBase64" alt=""
                *ngIf="user?.avatarBase64">

              <div class="popin-recruitersList-shortname" *ngIf="!user?.avatarBase64 && user?.prenom && !user?.nom">
                {{user?.prenom[0] | titlecase}}{{user?.prenom[1] | titlecase}}
              </div>

              <span>
                {{user?.prenom}}
              </span>
            </ng-container>

            <ng-container *ngIf="user?.recruteur?.prenom && !user?.recruteur?.nom">
              <img class="popin-recruitersList-avatar" [src]="'data:image/jpeg;base64,' + user?.avatarBase64" alt=""
                *ngIf="user?.avatarBase64">

              <div class="popin-recruitersList-shortname"
                *ngIf="!user?.recruteur?.avatarBase64 && user?.recruteur?.prenom && !user?.recruteur?.nom">
                {{user?.recruteur?.prenom[0] | titlecase}}{{user?.recruteur?.prenom[1] | titlecase}}
              </div>

              <span>
                {{user?.recruteur?.prenom}}
              </span>

            </ng-container>

          </div>
          <div fxFlex="25" style="overflow: hidden;">
            <mat-form-field class="select-default" style="padding-right: 15px" *ngIf="!isManagerList">

              <!-- // MULTIPLE select -->
              <!-- <mat-select #select [formControl]="user.rolesForm" multiple (selectionChange)="updateRoles($event)"> -->

              <!-- // SIMPLE select -->
              <mat-select #select [formControl]="user.rolesForm" disabled="true">
                <ng-container *ngFor="let role of roles">
                  <mat-option class="select-border__option" [value]="role.id" [id]="role.id"
                    style="text-transform: none;">
                    {{ role.nom }}
                  </mat-option>
                </ng-container>
              </mat-select>

            </mat-form-field>
          </div>

          <div fxFlex="25" style="flex-wrap: wrap; display: flex; align-items: center; font-weight: normal;">

            <ng-container *ngIf="user?.recruteur?.statut?.toLowerCase() === 'actif'">
              <span class="dot green"></span>
            </ng-container>

            <ng-container *ngIf="user?.recruteur?.statut?.toLowerCase() === 'invitation en attente'">
              <span class="dot orange"></span>
            </ng-container>

            <ng-container *ngIf="user?.recruteur?.statut?.toLowerCase() === 'désactivé'">
              <span class="dot grey"></span>
            </ng-container>


            <ng-container *ngIf="user?.recruteur?.statut?.toLowerCase() === 'invitation expirée'">
              <span class="dot red"></span>
            </ng-container>
            {{user?.recruteur?.statut}}
          </div>

        </div>

      </mat-list-item>
    </mat-list>



  </div>


  <!-- <mat-dialog-actions fxLayout="{{isMobile ? 'column' : 'row'}}" fxLayout.gt-sm="row" fxLayout.lt-md="column"
    [fxLayoutAlign]="'space-between center'" fxLayoutGap.lt-md="15px" *ngIf="isPopin"> -->

  <mat-dialog-actions fxLayout="{{isMobile ? 'column-reverse' : 'row'}}" fxLayout.gt-sm="row"
    fxLayout.lt-md="column-reverse"
    fxLayoutAlign="{{isPopin && isSelectionList ? 'space-between center' : 'flex-end center'}}" fxLayoutGap.lt-md="15px"
    style="margin-top: 15px;">

    <button fxFlex.lt-md="100" fxFlex.gt-sm="200px" [ngStyle.lt-md]="{'width' : '100%'}" mat-button
      class="btn btn--secondary btn--font-normal" mat-dialog-close [disabled]="isSubmitting"
      [class.disabled]="isSubmitting" (click)="dialogRef.close()" *ngIf="isPopin">
      <div fxFlex> Fermer</div>
    </button>

    <button fxFlex.lt-md="100" fxFlex.gt-sm="200px" [ngStyle.lt-md]="{'width' : '100%'}" mat-button
      class="btn btn--font-normal" [disabled]="isSubmitting" [class.disabled]="isSubmitting" (click)="updateUsers()"
      *ngIf="isSelectionList">
      <mat-spinner diameter="20" *ngIf="isSubmitting"></mat-spinner>
      <div fxFlex>Valider</div>
    </button>



  </mat-dialog-actions>
</ng-container>